import { LibNpawPluginBase } from './npaw-plugin-base';
import * as youbora from 'youboralib';


class LibNpawPluginFlutterWeb implements LibNpawPluginBase {
    private npawPlugin: any | null = null;
    private flutterAdapter: FlutterAdapter | null = null;

    getPlatformVersion(): Promise<string | null> {
        const userAgent = navigator.userAgent;
        const browserVersionMatch = userAgent.match(/(Chrome|Safari|Firefox|Edge|MSIE|Opera|Trident)(?:\/)([\d.]+)/);

        if (browserVersionMatch && browserVersionMatch.length >= 3) {
            const browserName = browserVersionMatch[1];
            const version = browserVersionMatch[2];
            return Promise.resolve(`Web ${browserName}/${version}`);
        } else {
            return Promise.resolve('Web Unknown');
        }
    }
    setupNpawPlugin(accountCode: string): Promise<void> {
        this.npawPlugin = new youbora.Plugin({ accountCode: accountCode });
        this.flutterAdapter = new FlutterAdapter();
        this.npawPlugin.setAdapter(this.flutterAdapter);
        return Promise.resolve();
    }
    setLogLevel(logLevel: number): Promise<void> {
        switch (logLevel) {
            case 6:
                youbora.Log.logLevel = youbora.Log.Level.SILENT
                break;
            case 5:
                youbora.Log.logLevel = youbora.Log.Level.ERROR
                break;
            case 4:
                youbora.Log.logLevel = youbora.Log.Level.WARNING
                break;
            case 3:
                youbora.Log.logLevel = youbora.Log.Level.NOTICE
                break;
            case 2:
                youbora.Log.logLevel = youbora.Log.Level.DEBUG
                break;
            case 1:
                youbora.Log.logLevel = youbora.Log.Level.VERBOSE
                break;
            default:
                throw new Error(`Invalid Log Level: ${logLevel}`);
        }
        return Promise.resolve();
    }
    setAccountCode(accountCode: string): Promise<void> {
        this.npawPlugin.setOptions({ 'accountCode': accountCode });
        return Promise.resolve();
    }
    setUsername(username: string): Promise<void> {
        this.npawPlugin.setOptions({ 'user.name': username });
        return Promise.resolve();
    }
    setUserType(userType: string): Promise<void> {
        this.npawPlugin.setOptions({ 'user.type': userType });
        return Promise.resolve();
    }
    setUserEmail(userEmail: string): Promise<void> {
        this.npawPlugin.setOptions({ 'user.email': userEmail });
        return Promise.resolve();
    }
    setDeviceCode(deviceCode: string): Promise<void> {
        this.npawPlugin.setOptions({ 'device.code': deviceCode });
        return Promise.resolve();
    }
    setDeviceModel(deviceModel: string): Promise<void> {
        this.npawPlugin.setOptions({ 'device.model': deviceModel });
        return Promise.resolve();
    }
    setDeviceBrand(deviceBrand: string): Promise<void> {
        this.npawPlugin.setOptions({ 'device.brand': deviceBrand });
        return Promise.resolve();
    }
    setDeviceType(deviceType: string): Promise<void> {
        this.npawPlugin.setOptions({ 'device.type': deviceType });
        return Promise.resolve();
    }
    setDeviceOsName(deviceOsName: string): Promise<void> {
        this.npawPlugin.setOptions({ 'device.osName': deviceOsName });
        return Promise.resolve();
    }
    setDeviceOsVersion(deviceOsVersion: string): Promise<void> {
        this.npawPlugin.setOptions({ 'device.osVersion': deviceOsVersion });
        return Promise.resolve();
    }
    setDeviceIsAnonymous(deviceIsAnonymous: boolean): Promise<void> {
        this.npawPlugin.setOptions({ 'device.isAnonymous': deviceIsAnonymous });
        return Promise.resolve();
    }
    setContentResource(contentResource: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.resource': contentResource });
        return Promise.resolve();
    }
    setContentIsLive(contentIsLive: boolean): Promise<void> {
        this.npawPlugin.setOptions({ 'content.isLive': contentIsLive });
        return Promise.resolve();
    }
    setContentIsLiveNoSeek(contentIsLiveNoSeek: boolean): Promise<void> {
        this.npawPlugin.setOptions({ 'content.isLive.noSeek': contentIsLiveNoSeek });
        return Promise.resolve();
    }
    setContentTitle(contentTitle: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.title': contentTitle });
        return Promise.resolve();
    }
    setProgram(program: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.program': program });
        return Promise.resolve();
    }
    setContentDuration(contentDuration: number): Promise<void> {
        this.npawPlugin.setOptions({ 'content.duration': contentDuration });
        return Promise.resolve();
    }
    setContentBitrate(contentBitrate: number): Promise<void> {
        this.npawPlugin.setOptions({ 'content.bitrate': contentBitrate });
        this.flutterAdapter?.setBitrate(contentBitrate);
        return Promise.resolve();
    }
    setContentThroughput(contentThroughput: number): Promise<void> {
        this.npawPlugin.setOptions({ 'content.throughput': contentThroughput });
        return Promise.resolve();
    }
    setContentRendition(contentRendition: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.rendition': contentRendition });
        this.flutterAdapter?.setRendition(contentRendition);
        return Promise.resolve();
    }
    setContentLanguage(contentLanguage: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.language': contentLanguage });
        return Promise.resolve();
    }
    setAppName(appName: string): Promise<void> {
        this.npawPlugin.setOptions({ 'app.name': appName });
        return Promise.resolve();
    }
    setAppReleaseVersion(appReleaseVersion: string): Promise<void> {
        this.npawPlugin.setOptions({ 'app.releaseVersion': appReleaseVersion });
        return Promise.resolve();
    }
    setCustomDimension1(customDimension1: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.1': customDimension1 });
        return Promise.resolve();
    }
    setCustomDimension2(customDimension2: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.2': customDimension2 });
        return Promise.resolve();
    }
    setCustomDimension3(customDimension3: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.3': customDimension3 });
        return Promise.resolve();
    }
    setCustomDimension4(customDimension4: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.4': customDimension4 });
        return Promise.resolve();
    }
    setCustomDimension5(customDimension5: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.5': customDimension5 });
        return Promise.resolve();
    }
    setCustomDimension6(customDimension6: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.6': customDimension6 });
        return Promise.resolve();
    }
    setCustomDimension7(customDimension7: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.7': customDimension7 });
        return Promise.resolve();
    }
    setCustomDimension8(customDimension8: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.8': customDimension8 });
        return Promise.resolve();
    }
    setCustomDimension9(customDimension9: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.9': customDimension9 });
        return Promise.resolve();
    }
    setCustomDimension10(customDimension10: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.10': customDimension10 });
        return Promise.resolve();
    }
    setCustomDimension11(customDimension11: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.11': customDimension11 });
        return Promise.resolve();
    }
    setCustomDimension12(customDimension12: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.12': customDimension12 });
        return Promise.resolve();
    }
    setCustomDimension13(customDimension13: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.13': customDimension13 });
        return Promise.resolve();
    }
    setCustomDimension14(customDimension14: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.14': customDimension14 });
        return Promise.resolve();
    }
    setCustomDimension15(customDimension15: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.15': customDimension15 });
        return Promise.resolve();
    }
    setCustomDimension16(customDimension16: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.16': customDimension16 });
        return Promise.resolve();
    }
    setCustomDimension17(customDimension17: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.17': customDimension17 });
        return Promise.resolve();
    }
    setCustomDimension18(customDimension18: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.18': customDimension18 });
        return Promise.resolve();
    }
    setCustomDimension19(customDimension19: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.19': customDimension19 });
        return Promise.resolve();
    }
    setCustomDimension20(customDimension20: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.customDimension.20': customDimension20 });
        return Promise.resolve();
    }
    setContentCdn(contentCdn: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.cdn': contentCdn });
        return Promise.resolve();
    }
    setContentStreamingProtocol(contentStreamingProtocol: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.streamingProtocol': contentStreamingProtocol });
        return Promise.resolve();
    }
    setContentPackage(contentPackage: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.package': contentPackage });
        return Promise.resolve();
    }
    setContentSaga(contentSaga: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.saga': contentSaga });
        return Promise.resolve();
    }
    setContentTvShow(contentTvShow: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.tvShow': contentTvShow });
        return Promise.resolve();
    }
    setContentSeason(contentSeason: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.season': contentSeason });
        return Promise.resolve();
    }
    setContentEpisodeTitle(contentEpisodeTitle: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.episodeTitle': contentEpisodeTitle });
        return Promise.resolve();
    }
    setContentChannel(contentChannel: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.channel': contentChannel });
        return Promise.resolve();
    }
    setContentId(contentId: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.id': contentId });
        return Promise.resolve();
    }
    setContentGracenoteId(contentGracenoteId: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.gracenoteId': contentGracenoteId });
        return Promise.resolve();
    }
    setContentType(contentType: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.type': contentType });
        return Promise.resolve();
    }
    setContentGenre(contentGenre: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.genre': contentGenre });
        return Promise.resolve();
    }
    setContentSubtitles(contentSubtitles: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.subtitles': contentSubtitles });
        return Promise.resolve();
    }
    setContentPlaybackType(contentPlaybackType: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.playbackType': contentPlaybackType });
        return Promise.resolve();
    }
    setContentDrm(contentDrm: string): Promise<void> {
        this.npawPlugin.setOptions({ 'content.drm': contentDrm });
        return Promise.resolve();
    }
    startSession(screenName: string, dimensions?: Record<string, string> | undefined): Promise<void> {
        // this is for app analytics so we don't need to implement it
        return Promise.resolve();
    }
    endSession(): Promise<void> {
        // this is for app analytics so we don't need to implement it
        return Promise.resolve();
    }
    navigation(screenName: string): Promise<void> {
        // this is for app analytics so we don't need to implement it
        return Promise.resolve();
    }
    customEvent(eventName: string, dimensions?: Record<string, string> | undefined, values?: Record<string, number> | undefined): Promise<void> {
        // this is for app analytics so we don't need to implement it
        return Promise.resolve();
    }
    fireInit(): Promise<void> {
        this.npawPlugin?.fireInit();      
        return Promise.resolve();
    }

    fireStart(): Promise<void> {
        this.flutterAdapter?.fireStart();
        return Promise.resolve();
    }
    fireJoin(): Promise<void> {
        this.flutterAdapter?.fireJoin();
        return Promise.resolve();
    }
    firePause(): Promise<void> {
        this.flutterAdapter?.firePause();
        return Promise.resolve();
    }
    fireResume(): Promise<void> {
        this.flutterAdapter?.fireResume();
        return Promise.resolve();
    }
    fireBufferBegin(): Promise<void> {
        this.flutterAdapter?.fireBufferBegin();
        return Promise.resolve();
    }
    fireBufferEnd(): Promise<void> {
        this.flutterAdapter?.fireBufferEnd();
        return Promise.resolve();
    }
    fireSeekBegin(): Promise<void> {
        this.flutterAdapter?.fireSeekBegin();
        return Promise.resolve();
    }
    fireSeekEnd(): Promise<void> {
        this.flutterAdapter?.fireSeekEnd();
        return Promise.resolve();
    }
    fireStop(): Promise<void> {
        this.flutterAdapter?.fireStop();
        return Promise.resolve();
    }
    fireError(errorDescription: string): Promise<void> {
        this.flutterAdapter?.fireError(errorDescription);
        return Promise.resolve();
    }
    setPosition(position: string): Promise<void> {
        this.flutterAdapter?.setPosition(parseFloat(position));
        return Promise.resolve();
    }
    setPlayerVersion(playerVersion: string): Promise<void> {
        this.flutterAdapter?.setPlayerVersion(playerVersion);
        return Promise.resolve();
    }
    customVideoEvent(eventName: string, dimensions?: Record<string, string> | undefined, values?: Record<string, number> | undefined): Promise<void> {
        // no need custom video event for now
        return Promise.resolve();
    }
}

export { LibNpawPluginFlutterWeb };

class FlutterAdapter extends youbora.Adapter {

    private currentPosition: number = 0.0;
    private playerVersion: string = "";
    private bitrate: number = -1;
    private rendition: string = "";

    getPlayerName(): string {
        return 'flutter-video_player-web';
    }

    getPlayerVersion(): string {
        return this.playerVersion;
    }

    getVersion(): string {
        return "1.1.0-flutter-video_player-web";
    }

    getRendition(): string | undefined {
        return this.rendition;
    }

    getBitrate(): number | undefined {
        return this.bitrate;
    }

    getPlayhead(): number {
        return this.currentPosition;
    }

    setPosition(position: number): void {
        this.currentPosition = position;
    }

    setPlayerVersion(playerVersion: string): void {
        this.playerVersion = playerVersion;
    }

    setBitrate(bitrate: number): void {
        this.bitrate = bitrate;
    }

    setRendition(rendition: string): void {
        this.rendition = rendition;
    }
}